export const SubMenuArrow = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.405807 0.305123C0.13244 0.57849 0.13244 1.02171 0.405807 1.29507L4.11083 5.0001L0.405807 8.70512C0.13244 8.97849 0.13244 9.42171 0.405807 9.69507C0.679173 9.96844 1.12239 9.96844 1.39576 9.69507L5.59576 5.49507C5.86912 5.22171 5.86912 4.77849 5.59576 4.50512L1.39576 0.305123C1.12239 0.0317559 0.679173 0.0317559 0.405807 0.305123Z"
        fill="#212121"
      />
    </svg>
  );
};
